import { useCookies } from 'vue3-cookies';
import { authenticate } from '@commercelayer/js-auth';
import CommerceLayer from '@commercelayer/sdk';

export default class CLayerBaseService {
  constructor() {
    this.clEntity = null;
    this.clAmdmin = null;
  }

  // eslint-disable-next-line class-methods-use-this
  generateScope = (market) => `market:id:${market}`;

  createToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    // localStorage.setItem('marketNumber', '11948');
    const market = localStorage.getItem('marketId');
    if (!cookies.isKey('clAuthCookie')) {
      authenticate('client_credentials', {
        clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
        scope: this.generateScope(market),
      })
        .then((response) => {
          cookies.set('clAuthCookie', {
            access_token: response.accessToken,
          });
          this.clEntity = CommerceLayer({
            organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
            accessToken: response.accessToken,
          });
          resolve(this.clEntity);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      this.clEntity = CommerceLayer({
        organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
        accessToken: cookies.get('clAuthCookie').access_token,
      });
      resolve(this.clEntity);
    }
  });

  createAdminToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    authenticate('client_credentials', {
      clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_ID,
      clientSecret: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_CLIENT_SECRET,
    })
      .then((response) => {
        cookies.set('clUserAuthAdminCookie', {
          access_token: response.accessToken,
        });
        this.clAmdmin = CommerceLayer({
          organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ADMIN_ORG,
          accessToken: response.accessToken,
        });
        resolve(this.clAmdmin);
      })
      .catch((error) => {
        reject(error);
      });
  });

  // eslint-disable-next-line class-methods-use-this
  createUserToken = (credentials) => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    // localStorage.setItem('marketNumber', '11948');
    const market = localStorage.getItem('marketId');
    if (!cookies.isKey('clUserAuthCookie')) {
      authenticate('password', {
        clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
        scope: this.generateScope(market),
        username: credentials.email,
        password: credentials.password,
      })
        .then((response) => {
          cookies.set('clUserAuthCookie', {
            access_token: response.accessToken,
          });
          cookies.set('clUserAuthRefreshCookie', {
            refresh_token: response.refreshToken,
          });
          cookies.set('clUserOwnerId', {
            owner_id: response.ownerId,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

  refreshToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    const refresh = cookies.get('clUserAuthRefreshCookie');
    const market = localStorage.getItem('marketId');
    authenticate('refresh_token', {
      clientId: process.env.VUE_APP_COMMERCE_LAYER_SC_CLIENT_ID,
      scope: this.generateScope(market),
      refreshToken: refresh.refresh_token,
    })
      .then((response) => {
        cookies.set('clUserAuthCookie', {
          access_token: response.accessToken,
        });
        cookies.set('clUserAuthRefreshCookie', {
          refresh_token: response.refreshToken,
        });
        cookies.set('clUserOwnerId', {
          owner_id: response.ownerId,
        });
        this.clEntity = CommerceLayer({
          organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
          accessToken: response.accessToken,
        });
        resolve(this.clEntity);
      })
      .catch((error) => {
        reject(error);
      });
  });

  getToken = () => new Promise((resolve, reject) => {
    const { cookies } = useCookies({ secure: true });
    if (cookies.isKey('clUserAuthCookie')) {
      const token = cookies.get('clUserAuthCookie');
      this.clEntity = CommerceLayer({
        organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
        accessToken: token.access_token,
      });
      this.clEntity.addResponseInterceptor(undefined, (err) => {
        // eslint-disable-next-line no-underscore-dangle
        if (err.response.status === 401 && err.response.data.errors[0].code === 'INVALID_TOKEN') {
          this.refreshToken().then(() => {
            window.location.reload();
          });
        }
      });
      resolve(this.clEntity);
    } else {
      this.clEntity = CommerceLayer({
        organization: process.env.VUE_APP_COMMERCE_LAYER_SC_ORG,
        accessToken: cookies.get('clAuthCookie').access_token,
      });
      this.clEntity.addResponseInterceptor(undefined, (err) => {
        // eslint-disable-next-line no-underscore-dangle
        if (err.response.status === 401 && err.response.data.errors[0].code === 'INVALID_TOKEN') {
          cookies.remove('clAuthCookie');
          this.createToken().then(() => {
            window.location.reload();
          });
        }
      });
      resolve(this.clEntity);
    }
  });
}
