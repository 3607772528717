<template>
    <PageHeader />
    <section class="blog">
      <div class="container">
        <div class="row">
          <div class="col-md-10 m-auto">
            <img src="@/assets/images/MajikKids2.jpg"  alt="">
            <h5 class="mt-4 mb-3">
                ONANOFF Partners with Majik Kids to Bring New Stories to StoryShields!
            </h5>
            <p class="blog-p">
                Exciting things are on the horizon for young listeners and their families! ONANOFF and
                StoryPhones are thrilled to announce a wonderful new collaboration with
                <a href="https://majikkids.com/" class="">MajikKids</a>, creators of heartwarming and imaginative
                stories designed to captivate children and inspire families. Together, we’re bringing
                <b>four brand-new enchanting stories</b> to life as part of our ever-expanding <b>StoryShields</b>
                library, launching in <b>April 2025</b>.
            </p>
            <p class="blog-p">
                Majik Kids has built a reputation for weaving magical tales that spark curiosity,
                empathy, and joy. Their stories are not just entertainment—they're opportunities for
                children to explore unique worlds, meet fascinating characters, and learn valuable life
                lessons. That’s why teaming up with Majik Kids feels like the perfect match for
                StoryPhones mission to provide safe, fun, and enriching audio experiences.
            </p>
            <h5 class="mt-4 mb-3">Why This Partnership Matters </h5>
            <p class="blog-p">
                At ONANOFF, we believe that storytelling is a powerful way to nurture creativity, lessen
                screen-time, and strengthen family bonds. With Majik Kids on board, we’re taking
                this vision to new heights by offering original content for StoryPhones that goes beyond fun.
                These new stories invite children to dream big, spark their imaginations, and grow in
                understanding of themselves and the world around them
            </p>
            <p class="blog-p">
                “We are excited to partner with Majik Kids to offer original content that deepens the
                value of our StoryShields library,” said <b>Petur Olafsson</b>, CEO of ONANOFF. “These
                stories are more than just entertainment—they’re an invitation for children to imagine,
                learn, and grow. Together, we’re offering families a screen-free way to share
                transformative audio experiences that bring people closer.”
            </p>
            <h5 class="mt-4 mb-3">What to Expect </h5>
            <p class="blog-p">
                With these upcoming StoryShields, kids and families can look forward to:
                <ul class="ps-5 mb-3">
                  <li>
                    <p class="blog-p">
                    <b>Magical adventures</b> filled with mystery, wonder, and excitement.
                  </p>
                  </li>
                  <li>
                    <p class="blog-p">
                   <b>Unique characters</b> that children will love and relate to.</p>
                  </li>
                  <li>
                    <p class="blog-p">
                  <b>Meaningful themes</b> that spark thoughtful conversations and inspire kindness.</p>
                  </li>
                </ul>
            </p>
            <p class="blog-p">
              Whether it’s a quiet evening at home, a long road trip, or just time to unwind,
              StoryPhones from ONANOFF offers a screen-free, enriching way for families to enjoy
              storytelling together. These new additions will further enhance our library of safe,
              educational, and imaginative audio content.
            </p>
            <h5 class="mt-4 mb-3">Stay Tuned! </h5>
            <p class="blog-p">
                We can’t wait to reveal more about these magical stories in the coming months. Keep
                an eye out for updates as we get closer to the launch in <b>April 2025</b>. There are
                unforgettable characters to meet, thrilling adventures to embark on, and plenty of imagination to spark!
            </p>
            <p class="blog-p">
                At ONANOFF, we remain committed to creating products and experiences that foster
                fun, learning, and connection. Our partnership with Majik Kids is another exciting step
                toward that goal, and we can’t wait to share it with you!
              </p>
              <p class="blog-p">
                Until then, happy listening and happy storytelling!
              </p>
              <p>
                Learn more about Majik Kids <a href="https://majikkids.com/about/" class="">HERE</a>.
              </p>
          </div>
        </div>
      </div>
      <div class="container-fluid text-center mt-4 mb-5">
        <div class="wave-btn-block">
          <router-link to="/blog" class="blob-btn fill-bg btn-lg">
            Back to Blog
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </router-link>
        </div>
      </div>
    </section>
    <PageFooter />
  </template>

<script>
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Blog',

  components: {
    PageHeader,
    PageFooter,
  },
};
</script>
<style></style>
