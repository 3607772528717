<template lang="html">
  <div class="d-flex justify-content-between my-4 middle-block" :class="{ hidebutton: hideButton }">
    <div class="qty-action-block d-flex justify-content-center">
      <div class="cart-success-block">
        <button
          class="product-count btn"
          :class="{ hidecart: minusOne }"
          @click="reduceOneQuantity(cartData.id, cartData.quantity, cartData.sku_code)"
        >
          <i class="icon-minus" aria-hidden="true"></i>
        </button>
        <button
          class="product-count btn success-only d-none animate__animated animate__jackInTheBox"
          :class="{ addsuccess: minusOne }"
        >
          -1
        </button>
      </div>

      <span class="counting">{{ cartData.quantity }}</span>
      <div class="cart-success-block">
        <button
          class="product-count btn"
          :class="{ hidecart: plusOne }"
          @click="addOneQuantity(cartData.id, cartData.quantity, cartData.sku_code)"
        >
          <i class="icon-plus" aria-hidden="true"></i>
        </button>
        <button
          class="product-count btn success-only d-none animate__animated animate__jackInTheBox"
          :class="{ addsuccess: plusOne }"
        >
          +1
        </button>
      </div>
    </div>
    <div
      v-if="cartData.sku_code !== '4897111741467' && cartData.sku_code !== '4897111740842'"
      class="cart-success-block"
    >
      <button
        type="button"
        class="border-0 delete"
        aria-label="Remove"
        :class="{ hidecart: deleteSuccess }"
        @click="deleteShield(cartData.id)"
      >
        <i class="icon-delete" aria-hidden="true"></i>
      </button>
      <button
        class="border-0 delete success-only d-none animate__animated animate__jackInTheBox"
        :class="{ addsuccess: deleteSuccess }"
      >
        <i class="icon-checklist" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <!-- <div  v-show="modalActive" class="offcanvas-body">
    <div>
      <ul class="cart-product-list">
        <li v-for="(shield, index) in playShieldPlus.items" :key="index">
          <div class="row">
            <img
              src="@/assets/images/playshield-white.png"
              class="rounded-circle"
              style="width: 5rem"
              alt="img"
            />
            <div class="col-7">
              <div class="description">
                {{ shield?.playlist?.title }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div> -->

  <div v-show="modalActive" class="checkout-modal p-3">
    <div class="text-end">
      <button type="button" class="btn-close" @click="closeModel()"></button>
    </div>
    <div v-if="errorMsg" class="error-msg text-center">
      {{ errorMsg }}
    </div>
    <ul class="card-modal-body mt-4">
      <li v-for="(shield, index) in playShieldPlus.items" :key="index">
        <div
          class="card"
          :class="{ active: index === isIndex }"
          @click="selected(index, shield.id, shield.sku)"
        >
          <div class="card-body">
            <img
              src="@/assets/images/playshield-white.png"
              class="rounded-circle"
              style="width: 5rem"
              alt="img"
            />
            <!-- :style="{ 'background-color': shield.color }" -->
            <label for="html">{{ shield?.playlist?.title }}</label>
          </div>
        </div>
      </li>
    </ul>
    <!-- <div class="card-modal-body mt-4">
      <div
        class="card mb-3"
        v-for="(shield, index) in playShieldPlus.items"
        :key="index"
        :class="{ active: index === isIndex }"
        @click="selected(index, shield.id, shield.sku)"
      >
        <div class="card-body">
          <img
            src="@/assets/images/playshield-white.png"
            class="rounded-circle"
            style="width: 5rem"
            alt="img"
          />
          :style="{ 'background-color': shield.color }"
          <label for="html">{{ shield?.playlist?.title }}</label>
        </div>
      </div>
    </div> -->
    <div class="checkout-footer mb-2">
      <div v-if="recordBtn" class="wave-btn-block">
        <button type="submit" class="blob-btn fill-bg btn-lg mt-4" @click="addPlaySheildPlus()">
          ADD PlayShield+
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
      </div>
      <div v-if="removeBtn" class="wave-btn-block">
        <button
          @click="removePlayShieldPlus(cartData.id, cartData.quantity)"
          type="submit"
          class="blob-btn btn-lg mt-4"
        >
          Remove
          <span class="blob-btn__inner">
            <span class="blob-btn__blobs">
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
              <span class="blob-btn__blob"></span>
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';

import CLayerService from '@/services/CLayerService';
import NewContentfulService from '@/services/NewContentfulService';
import StoryphoneService from '@/services/StoryphoneService';

const newContentfulService = new NewContentfulService();

const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();
const { cookies } = useCookies({ secure: true });

export default {
  name: 'CartItemButton',
  props: [
    'cartData',
  ],
  setup() {
    const store = useStore();
    const router = useRouter();
    const minusOne = ref(false);
    const plusOne = ref(false);
    const deleteSuccess = ref(false);
    const modalActive = ref(false);
    const isIndex = ref('');
    const playlistId = ref('');
    const playlistSku = ref('');
    const recordBtn = ref(false);
    const removeBtn = ref(false);
    const errorMsg = ref('');

    const hideButton = computed(() => store.getters.hideButton);
    const playShieldPlus = computed(() => store.getters.getPlayShieldPlus);

    const addPlayShieldQuantity = (sku, orderId) => {
      const refData = {
        product_type: 'playshield',
        sku,
        quantity: 1,
      };
      storyphoneService.addPlayShieldPlus(orderId, refData);
    };

    const addOneQuantity = (id, quantity, sku) => {
      const { orderId } = cookies.get('orderIdCookie');
      if (sku === '4897111741467') {
        modalActive.value = true;
        recordBtn.value = true;
        removeBtn.value = false;
        const Data = {
          refId: orderId,
          sku,
        };
        store.dispatch('fetchPlayShieldPlusByLineItems', Data);
      } else {
        plusOne.value = true;
        cLayerService.updateLineItem(id, quantity + 1)
          .then((updatedResponse) => {
            if (sku === '4897111740842') {
              addPlayShieldQuantity(sku, orderId);
            }
            store.dispatch('fetchCartItems', orderId)
              .then((cartItems) => {
                plusOne.value = false;
              });
          })
          .catch((error) => {
            store.dispatch('setOutOfStockMessage');
            plusOne.value = false;
          });
      }
    };

    const removePlayShieldQuantity = (sku, orderId) => {
      const Data = {
        refId: orderId,
        sku,
      };
      store.dispatch('fetchPlayShieldPlusByLineItems', Data)
        .then(() => {
          newContentfulService.deletePlayShieldPlusByLineItems(playShieldPlus.value.items[0].id);
        });
    };

    const reduceOneQuantity = (id, quantity, sku) => {
      const { orderId } = cookies.get('orderIdCookie');
      if (sku === '4897111741467') {
        recordBtn.value = false;
        removeBtn.value = true;
        modalActive.value = true;
        const Data = {
          refId: orderId,
          sku,
        };
        store.dispatch('fetchPlayShieldPlusByLineItems', Data);
      } else if (quantity === 1) {
        minusOne.value = true;
        if (sku === '4897111740842') {
          removePlayShieldQuantity(sku, orderId);
        }
        cLayerService.deleteLineItem(id)
          .then((deleteResponse) => {
            store.dispatch('fetchCartItems', orderId)
              .then((cartItems) => {
                this.minusOne = false;
              });
          });
      } else {
        minusOne.value = true;
        if (sku === '4897111740842') {
          removePlayShieldQuantity(sku, orderId);
        }
        cLayerService.updateLineItem(id, quantity - 1)
          .then((updatedResponse) => {
            store.dispatch('fetchCartItems', orderId)
              .then((cartItems) => {
                minusOne.value = false;
              });
          });
      }
    };

    const deleteShield = (productId) => {
      const { orderId } = cookies.get('orderIdCookie');
      deleteSuccess.value = true;
      cLayerService.deleteLineItem(productId)
        .then((deleteResponse) => {
          store.dispatch('fetchCartItems', orderId)
            .then((cartItems) => {
              deleteSuccess.value = false;
            });
        });
    };
    const closeModel = () => {
      modalActive.value = false;
    };
    const selected = (index, id, sku) => {
      isIndex.value = index;
      playlistId.value = id;
      playlistSku.value = sku;
    };

    const addPlaySheildPlus = () => {
      // router.push({ name: 'custom-playlist' });
      router.push({ name: 'custom-playlist' }).then(() => { router.go(); });
    };

    const removePlayShieldPlus = (id, quantity) => {
      const { orderId } = cookies.get('orderIdCookie');
      if (quantity === 1 && isIndex.value !== '') {
        newContentfulService.deletePlayShieldPlusByLineItems(playlistId.value).then(() => {
          cLayerService.deleteLineItem(id)
            .then((deleteResponse) => {
              store.dispatch('fetchCartItems', orderId);
              modalActive.value = false;
              isIndex.value = '';
            });
        });
      } else if (isIndex.value !== '') {
        newContentfulService.deletePlayShieldPlusByLineItems(playlistId.value).then(() => {
          cLayerService.updateLineItem(id, quantity - 1)
            .then((deleteResponse) => {
              store.dispatch('fetchCartItems', orderId);
              modalActive.value = false;
              isIndex.value = '';
            });
        });
      } else {
        errorMsg.value = 'Select the Shield to Remove';
        setTimeout(() => {
          errorMsg.value = '';
        }, 2000);
      }
    };

    return {
      minusOne,
      plusOne,
      deleteSuccess,
      hideButton,
      addOneQuantity,
      reduceOneQuantity,
      deleteShield,
      closeModel,
      modalActive,
      playShieldPlus,
      isIndex,
      selected,
      addPlaySheildPlus,
      removePlayShieldPlus,
      playlistId,
      playlistSku,
      recordBtn,
      removeBtn,
      errorMsg,
    };
  },
};
</script>
