/* eslint-disable no-unused-vars */

const isObj = require('lodash.get');

export const getAsia = (state) => {
  const countries = [];

  return countries;
};

export const getEurope = (state) => {
  const countries = [
    {
      id: 'rlEPzhvXOg',
      name: 'Austria',
      image: 'austria',
      langCode: 'de',
      language: 'Deutsch',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Ireland',
      image: 'ireland',
      langCode: 'en',
      language: 'English',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'France',
      image: 'france',
      langCode: 'fr',
      language: 'Français',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Germany',
      image: 'germany',
      langCode: 'de',
      language: 'Deutsch',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Italy',
      image: 'italy',
      langCode: 'it',
      language: 'Italiano',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Spain',
      image: 'spain',
      langCode: 'es',
      language: 'Española',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'United Kingdom',
      image: 'united-kingdom',
      langCode: 'en',
      language: 'English',
      currency: '£ GBP',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Switzerland | DE',
      image: 'switzerland',
      langCode: 'de',
      language: 'Français',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Switzerland | FR',
      image: 'switzerland',
      langCode: 'fr',
      language: 'Français',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Switzerland | IT',
      image: 'switzerland',
      langCode: 'it',
      language: 'Français',
      currency: '€ EUR',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'United States',
      image: 'united-states',
      langCode: 'en',
      language: 'English',
      currency: '$ USD',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'Canada',
      image: 'canada',
      langCode: 'en',
      language: 'English',
      currency: '$ CAD',
      currencyCode: 8689,
    },
    {
      id: 'rlEPzhvXOg',
      name: 'International',
      image: 'global',
      langCode: 'en',
      language: 'English',
      currency: '$ INR',
      currencyCode: 8689,
    },
  ];

  return countries;
};

export const getNorthAmerica = (state) => {
  const countries = [
    {
      id: 'vlkaZhnKMj',
      name: 'United States',
      image: 'united-states',
      langCode: 'en',
      language: 'English',
      currency: '$ USD',
      currencyCode: 12007,
    },
    {
      id: 'pgMvehWByl',
      name: 'Canada',
      image: 'canada',
      langCode: 'en',
      language: 'English',
      currency: '$ CAD',
      currencyCode: 12008,
    },
  ];

  return countries;
};

export const getOceania = (state) => {
  const countries = [];

  return countries;
};

export const getSouthAmerica = (state) => {
  const countries = [];

  return countries;
};

export const getAfrica = (state) => {
  const countries = [];

  return countries;
};
