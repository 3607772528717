<template lang="html">
  <section class="overview">
    <div
      class="overview-block"
      :class="{
        showOverview: showOverview,
      }"
    >
      <div class="text-center">
        <h4 class="mb-1">{{ $t('Overview') }}</h4>
      </div>

      <div class="white-box mt-3">
        <div class="overview-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item border-0">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {{ $t('name') }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h4>{{ this.shieldName }}</h4>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {{ $t('recording') }}
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="recording-list">
                    <ul v-if="recordingList">
                      <li v-for="(element, index) in recordingList" :key="index">
                        <div class="d-flex justify-content-between">
                          <h4 class="m-0">{{ element.name }}</h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item border-0">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ $t('artwork') }}
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="cover-artwork" :style="{ 'background-color': backgroundColor }">
                    <img v-if="artWork" :src="artWork" alt="" class="border-0 p-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg me-2" @click="overViewBack()">
            {{ $t('back') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
        <div v-if="quantities > 0" class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg" @click="AddPlayShield()">
            <i class="icon-cart me-2" aria-hidden="true"></i>{{ $t('AddToCart') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
        <div v-else>
          <button class="add-to-cart disabled btn-lg" disabled>
            <span class="default"
              ><i class="icon-cart-close me-1" aria-hidden="true"></i> {{ $t('outOfStock') }}</span
            >
          </button>
        </div>
      </div>
      <div
        class="success-submit-box modal fade mt-3 d-none"
        :class="{ addsuccess: isAdded, show: isAdded }"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0 p-3">
            <div class="modal-body">
              <div class="popup-msg-box">
                <p>{{ $t('cartMessage') }}</p>
              </div>
            </div>
            <div class="text-center">
              <router-link to="/custom-playlist" class="btn btn-primary btn-lg submit-btn mb-3">
                {{ $t('ok') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-tabs -->
<script lang="js">

import { mapActions, mapGetters } from 'vuex';
import { useCookies } from 'vue3-cookies';
import moment from 'moment';

import SvgButton from '@/components/partials/SvgButton.vue';
import StoryphoneService from '@/services/StoryphoneService';
import CLayerService from '@/services/CLayerService';

const cLayerService = new CLayerService();
const { cookies } = useCookies({ secure: true });
const storyphoneService = new StoryphoneService();

export default {
  name: 'overview',
  props: ['shieldName'],
  components: {
    SvgButton,
  },

  data() {
    return {
      name: '',
      isPauseActive: false,
      isPlayActive: true,
      isAdded: false,
      quantities: 0,
      file: '',
    };
  },
  watch: {
    artWork: {
      handler() {
        this.getQuantity();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    convertImageURLtoFile(url, callback) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a new File object from the Blob
          const file = new File([blob], 'image.jpg', { type: blob.type });
          // Invoke the callback with the file object
          this.file = file;
          // callback(file);
        })
        .catch((error) => {
          const urlRegex = /^https?:\/\/.+/;
          const isDataURL = url.startsWith('data:');
          if (urlRegex.test(url) && !isDataURL) {
            // For default artwork
            this.file = url;
          }
          console.error('Error converting image URL to file:', error);
        });
    },

    overViewBack() {
      this.isShowArtworkAction(true);
      this.isShowOverviewAction(false);
      this.inFourthStepAction(false);
    },

    getQuantity() {
      let sku;
      if (this.artWork) {
        sku = 4897111741467;
      } else {
        sku = 'customPlayShieldWithoutArtwork';
      }
      cLayerService.getShieldPrice(sku)
        .then((response) => {
          this.quantities = response[0]?.stock_items[0]?.quantity;
        })
        .catch((error) => {
          console.log('error');
        });
    },

    AddPlayShield() {
      let data = {};
      if (this.artWork) {
        data = {
          itemDetails: {
            sku: 4897111741467,
          },
        };
      } else {
        data = {
          itemDetails: {
            sku: 'customPlayShieldWithoutArtwork',
          },
        };
      }
      this.convertImageURLtoFile(this.artWork);
      this.updateLineItemAction(data)
        .then(() => {
          const creativeStories = [];
          this.recordingList.forEach((element) => {
            creativeStories.push(element.id);
          });
          // if (this.artWork) {
          //   const dataURLtoFile = (dataurl, filename) => {
          //     const arr = dataurl.split(',');
          //     const mime = arr[0].match(/:(.*?);/)[1];
          //     const bstr = atob(arr[1]);
          //     let n = bstr.length;
          //     const u8arr = new Uint8Array(n);
          //     while (n) {
          //       u8arr[n - 1] = bstr.charCodeAt(n - 1);
          //       n -= 1; // to make eslint happy
          //     }
          //     return new File([u8arr], filename, { type: mime });
          //   };
          //   const file = dataURLtoFile(this.artWork, 'image.jpeg');
          const formData = new FormData();
          formData.append('title', this.shieldName);
          formData.append('type', 'playshield-playlist');
          formData.append('gtin', new Date().toISOString());
          formData.append('image', this.file);

          creativeStories.forEach((item, index) => {
            const storyObject = {
              order: index + 1,
              story: item,
            };
            formData.append('stories[]', JSON.stringify(storyObject));
          });

          // formData.append('shield_details', shield.data.id);
          // storyphoneService.imageUpload(formData);
          // }
          // const playList = {
          //   title: `${this.shieldName} ${moment(new Date()).format('h:mm:ss a')}`,
          //   stories: creativeStories,
          //   image: this.formData,
          // };
          this.isAdded = true;
          storyphoneService.playListCreation(formData).then((playlist) => {
            const { orderId } = cookies.get('orderIdCookie');
            const refData = {
              product_type: 'playshield-plus',
              sku: '4897111741467',
              playlist: playlist.data.id,
              color: this.backgroundColor || undefined,
              quantity: 1,
            };
            storyphoneService.addPlayShieldPlus(orderId, refData);
            // const playlistId = playlist.data.id;
            // const customShield = {
            //   name: this.shieldName,
            //   sku: 4897111741467,
            //   order_id: cookies.get('orderIdCookie').orderId,
            //   quantity: 1,
            //   color: this.backgroundColor,
            //   playlist: playlistId,
            // };
            // storyphoneService.customizeShields(customShield)
            //   .then((shield) => {
            //     if (this.artWork) {
            //       const dataURLtoFile = (dataurl, filename) => {
            //         const arr = dataurl.split(',');
            //         const mime = arr[0].match(/:(.*?);/)[1];
            //         const bstr = atob(arr[1]);
            //         let n = bstr.length;
            //         const u8arr = new Uint8Array(n);
            //         while (n) {
            //           u8arr[n - 1] = bstr.charCodeAt(n - 1);
            //           n -= 1; // to make eslint happy
            //         }
            //         return new File([u8arr], filename, { type: mime });
            //       };
            //       const file = dataURLtoFile(this.artWork, 'image.jpeg');
            //       const formData = new FormData();
            //       formData.append('image', file);
            //       formData.append('shield_details', shield.data.id);
            //       storyphoneService.imageUpload(formData);
            //     }
            //   });
          });
        });
    },

    ...mapActions({
      isShowArtworkAction: 'isShowArtwork',
      isShowOverviewAction: 'isShowOverview',
      updateLineItemAction: 'updateLineItem',
      inFourthStepAction: 'inFourthStep',
	    }),
  },
  computed: {
    ...mapGetters({
      recordingList: 'getRecordingList',
      backgroundColor: 'setBackgroundColor',
      artWork: 'setArtWork',
      showOverview: 'isShowOverview',
    }),
  },
};
</script>

<style scoped lang="scss"></style>
