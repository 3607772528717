<template lang="html">
  <section class="select-artwork">
    <div
      class="select-artwork-block mb-5 text-center d-none"
      :class="{
        showArtwork: showArtwork,
      }"
    >
      <h4 class="mb-1">{{ $t('customiseArtwork') }}</h4>
      <!-- <p>If choose to upload your own artwork will cost extract fee $xx.xx</p> -->
      <div class="white-box mt-3">
        <div class="upload-artwork">
          <div class="circle-box" :style="{ 'background-color': selectedBackground }">
            <img v-if="selectedArtWork" :src="selectedArtWork" alt="" class="border-0 p-0" />
          </div>
          <button class="close" @click="removeImage()">
            <i class="icon-close-circle" aria-hidden="true"></i>
          </button>

          <div class="upload-block">
            <input
              ref="imageUploader"
              type="file"
              class="form-control-file"
              id="real-image-file"
              accept="image/*"
              hidden="hidden"
              @change="fileInputChange"
            />
            <button
              type="button"
              id="custom-button"
              class="btn btn-primary btn-lg upload mt-4"
              @click="openFile()"
            >
              <i class="icon-image" aria-hidden="true"></i> {{ $t('upload') }}
            </button>
          </div>
        </div>

        <div
          class="success-submit-box modal fade mt-3 d-none"
          :class="{ addsuccess: isAdded, show: isAdded }"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content border-0 p-3">
              <div class="modal-body">
                <button
                  type="button"
                  class="btn-close close-blue-btn popup"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="closePopup()"
                >
                  <i class="icon-close" aria-hidden="true"></i>
                </button>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="image-container">
                      <img id="image" class="image" alt="image" />
                    </div>
                  </div>
                  <div class="col-sm-6 m-auto">
                    <div id="image-preview" class="image-preview image-preview-lg"></div>
                  </div>
                </div>
                <button type="button" class="btn btn-primary btn-lg mt-4" @click="cropImage">
                  {{ $t('crop') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="success-submit-box modal fade mt-3 d-none"
          :class="{ addsuccess: isShow, show: isShow }"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-0 p-3">
              <div class="modal-body">
                <h6>{{ $t('uploadHeight') }}</h6>
              </div>
              <div class="text-center">
                <button
                  class="btn btn-primary btn-lg submit-btn mb-3"
                  data-dismiss="modal"
                  @click="closeAlert()"
                >
                  {{ $t('close') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="artwork-tab mt-5">
          <ul class="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                {{ $t('defaultArtworks') }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                {{ $t('backgroundColour') }}
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <ul v-if="artWorks" class="artwork-list">
                <li v-for="artWork in artWorks.items" :key="artWork.id">
                  <button
                    type="button"
                    class="border-0 p-0 d-block h-100 w-100"
                    @click="artWorkClick(artWork.image)"
                  >
                    <img :src="artWork.image" alt="" class="border-0 p-0" />
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <ul v-if="backgroundColors" class="artwork-list">

                <li
                  v-for="color in backgroundColors.items"
                  :key="color.id"
                  :style="{ 'background-color': color.color_code }"
                >
                  <button
                    type="button"
                    class="border-0 p-0 d-block h-100 w-100"
                    @click="backgroundClick(color.color_code)"
                  ></button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg" @click="goToFiles()">
            {{ $t('back') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
        <div class="wave-btn-block">
          <button class="blob-btn fill-bg btn-lg" @click="goToOverview()">
            {{ $t('next') }}
            <span class="blob-btn__inner">
              <span class="blob-btn__blobs">
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
                <span class="blob-btn__blob"></span>
              </span>
            </span>
          </button>
          <SvgButton />
        </div>
      </div>
      <div
        class="success-submit-box modal fade mt-3 d-none"
        :class="{ addsuccess: isVerified, show: isVerified }"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0 p-3">
            <div class="modal-body">
              <div class="popup-msg-box">
                <p>{{ $t('selectBgColour') }}</p>
              </div>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary btn-lg submit-btn mb-3"
                data-dismiss="modal"
                @click="closeVerifed()"
              >
                {{ $t('close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */

import { mapActions, mapGetters } from 'vuex';
import { onMounted, reactive, ref } from 'vue';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { useCookies } from 'vue3-cookies';

import SvgButton from '@/components/partials/SvgButton.vue';

let realFileBtn;
let cropDetail = reactive({});
let cropper = reactive({});
let imgFile = reactive({});
// const resultUrl = ref('');
const { cookies } = useCookies({ secure: true });

const axios = require('axios');

export default {
  name: 'select-artwork',
  props: [],
  components: {
    SvgButton,
  },
  mounted() {
	  realFileBtn = document.getElementById('real-image-file');
	  this.fetchArtWorkAction();
	  this.fetchBackgroundColorAction();
	  const image = document.getElementById('image');
	  cropper = new Cropper(image, {
	    aspectRatio: 1 / 1,
	    cropBoxResizable: false,
	    dragMode: 'none',
	    zoomable: false,
	    viewMode: 3,
	    preview: '#image-preview',
	    crop(e) {
        cropDetail = e.detail;
	    },
	  });
  },
  data() {
	  return {
	    selectedArtWork: '',
	    selectedBackground: '',
	    isAdded: false,
	    isShow: false,
      imageWidth: '',
      imageHeight: '',
      base64: '',
      isVerified: false,
    };
  },
  watch: {
    selectedArtWork: {
      immediate: true,
      deep: true,
    },
	  selectedBackground: {
      immediate: true,
      deep: true,
    },
    imageWidth: {
      immediate: true,
      deep: true,
    },
    imageHeight: {
      immediate: true,
      deep: true,
    },
  },

  methods: {
    openFile() {
      realFileBtn.click();
    },
    closePopup() {
      this.isAdded = false;
    },
    cropImage(e) {
      const result = cropper.getCroppedCanvas({
        width: 1000,
        height: 1000,
      });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const { width } = result;
      const { height } = result;

      canvas.width = width;
      canvas.height = height;
      context.webkitImageSmoothingEnabled = false;
      context.mozImageSmoothingEnabled = false;
      context.imageSmoothingEnabled = false;
      context.drawImage(result, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
      context.fill();
      const file = canvas.toDataURL();
	    this.selectedArtWork = file;
	    this.setArtWorkAction(file);
	    this.isAdded = false;
    },

    fileInputChange(e) {
      const file = e.target.files[0];
      if (!file || !file.type.includes('image/')) return;

      // eslint-disable-next-line no-throw-literal
      if (!(typeof FileReader === 'function')) { throw 'FileReader API not supported'; }
      imgFile = file;
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        // console.log(target);
        const image = new Image();
        image.src = target.result;
        const vm = this;
        image.onload = function () {
          // access image size here
          if (this.width > 300 && this.height > 300) {
		      vm.isShow = false;
		      vm.isAdded = true;
		      cropper.replace(target.result);
		      } else {
		        vm.isAdded = false;
		        vm.isShow = true;
		      }
        };
	    };
	      // resultUrl.value = imgFile;
	      reader.readAsDataURL(imgFile);
      this.$refs.imageUploader.value = '';
	    },

	    closeAlert() {
	      this.isShow = false;
	    },

    // generateBase64(url) {
    //   let canvas = document.createElement('canvas');
    //   const img = document.createElement('img');
    //   img.src = url;
    //   img.onload = () => {
    //     canvas.height = img.height;
    //     canvas.width = img.width;
    //     this.base64 = canvas.toDataURL();
    //     console.log(this.base64);
    //     this.setArtWorkAction(this.base64);
    //     canvas = null;
    //   };

    //   img.onerror = (error) => {
    //     this.error = 'Could not load image, please check that the file is accessible and an image!';
    //   };
    // },

    async downloadImage(url) {
      const raw = fetch(url)
        .then((response) => Buffer.from(response.data, 'binary').toString('base64'));
      // const img = new Image();
      // img.crossOrigin = 'Anonymous';
      // img.src = `data:image/jpeg;base64, ${base64}`;
      // console.log(img);
      // return img;
    },

    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },

	    async artWorkClick(image) {
	      this.selectedArtWork = image;
      // this.downloadImage(image);
      this.setArtWorkAction(image);
	    },

	    backgroundClick(background) {
	      this.selectedBackground = background;
	      this.setBackgroundColorAction(background);
	    },
    closeVerifed() {
      this.isVerified = false;
    },
	    removeImage() {
	    this.selectedArtWork = '';
	    this.selectedBackground = '';
      this.deleteArtWorkAction('');
      this.deleteBackgroundColorAction('');
	    },
    goToFiles() {
      this.isShowArtworkAction(false);
      this.isShowFilesAction(true);
      this.inThirdStepAction(false);
      // store.dispatch('inThirdStep', false);
    },
    goToOverview() {
      if (this.selectedArtWork === '') {
        this.isVerified = true;
      } else {
        this.isShowArtworkAction(false);
        this.isShowOverviewAction(true);
        this.inFourthStepAction(true);
        // store.dispatch('inFirstStep', true);
      }
    },
    	...mapActions({
	      fetchArtWorkAction: 'fetchArtWork',
	      fetchBackgroundColorAction: 'fetchBackgroundColor',
	      setArtWorkAction: 'setArtWork',
	      setBackgroundColorAction: 'setBackgroundColor',
      deleteArtWorkAction: 'deleteArtWork',
      deleteBackgroundColorAction: 'deleteBackgroundColor',
      isShowFilesAction: 'isShowFiles',
      isShowArtworkAction: 'isShowArtwork',
      isShowOverviewAction: 'isShowOverview',
      inThirdStepAction: 'inThirdStep',
      inFourthStepAction: 'inFourthStep',
	    }),
	  },
	  computed: {
	    ...mapGetters({
	      artWorks: 'getArtWork',
	      backgroundColors: 'getBackgroundColors',
      showArtwork: 'isShowArtwork',
	    }),
	  },
};
</script>

<style scoped lang="scss"></style>
