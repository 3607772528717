// eslint-disable-next-line object-curly-newline
import { IsAuthenticated, IsLoggedIn, IsUser, IsSelected } from '../middlewares/index';
import Home from '../pages/Home.vue';
import About from '../pages/About.vue';
import ProductCollections from '../pages/ProductCollections.vue';
import Cart from '../pages/Cart.vue';
import Checkout from '../pages/Checkout.vue';
import ComingSoon from '../pages/ComingSoon.vue';
import Signup from '../pages/Signup.vue';
import Login from '../pages/Login.vue';
import ForgetPassword from '../pages/ForgetPassword.vue';
import ResetPassword from '../pages/ResetPassword.vue';
import ResetPasswordEmail from '../pages/ResetPasswordEmail.vue';
import ConfirmEmail from '../pages/ConfirmEmail.vue';
import MyAccount from '../pages/MyAccount.vue';
import OrderHistory from '../pages/OrderHistory.vue';
import OrderHistoryDetails from '../pages/OrderHistoryDetails.vue';
import Wishlist from '../pages/Wishlist.vue';
import PlayShieldPlus from '../pages/PlayShieldPlus.vue';
import CustomPlaylist from '../pages/CustomPlaylist.vue';
import AddNewAddress from '../pages/AddNewAddress.vue';
import EditAddress from '../pages/EditAddress.vue';
import AffiliateProgram from '../pages/AffiliateProgram.vue';
import TermsAndConditions from '../pages/TermsAndConditions.vue';
import Shields from '../pages/Shields.vue';
import ContactUs from '../pages/ContactUs.vue';
import Privacy from '../pages/Privacy.vue';
import PrivacyPolicy from '../pages/PrivacyPolicy.vue';
import WarrantyRegistration from '../pages/WarrantyRegistration.vue';
import OperationGuide from '../pages/OperationGuide.vue';
import TermsOfService from '../pages/TermsOfService.vue';
import RefundPolicy from '../pages/RefundPolicy.vue';
import FAQ from '../pages/FAQ.vue';
import UserManual from '../pages/UserManual.vue';
import AppStoryphones from '../pages/AppStoryphones.vue';
import Test from '../pages/Test.vue';
import CartDetails from '../pages/CartDetails.vue';
import PaymentSuccess from '../pages/PaymentSuccess.vue';
import UpdateCart from '../pages/UpdateCart.vue';
import ProductDisney from '../pages/Disney.vue';
import Product from '../pages/Product.vue';
import ReviewsAndRatings from '../pages/ReviewsAndRatings.vue';
import AllShields from '../pages/AllShields.vue';
import Verification from '../pages/Verification.vue';
import SearchResult from '../pages/SearchResult.vue';
import LicenceAgreement from '../pages/LicenceAgreement.vue';
import SuccessPassword from '../pages/SuccessPassword.vue';
import RecordingPage from '../pages/RecordingPage.vue';
import Library from '../pages/Library.vue';
import GuestRecording from '../pages/GuestRecording.vue';
import Marketplace from '../pages/Marketplace.vue';
import MarketplaceProfile from '../pages/MarketplaceProfile.vue';
import MarketplaceStoryDetails from '../pages/MarketplaceStoryDetails.vue';
import MarketplaceLogin from '../pages/MarketplaceLogin.vue';
import MarketplaceSignup from '../pages/MarketplaceSignup.vue';
import MarketplaceUpdateProfile from '../pages/MarketplaceUpdateProfile.vue';
import MarketplaceMyPlaylist from '../pages/MarketplaceMyPlaylist.vue';
import MarketplacePayments from '../pages/MarketplacePayments1.vue';
import MarketplaceCreaterProfile from '../pages/MarketplaceCreaterProfile.vue';
import MarketPlaceHome from '../pages/MarketPlaceHome.vue';
import MarketplaceAuthorDetails from '../pages/MarketplaceAuthorDetails.vue';
import MystoryContent from '../pages/MystoryContent.vue';
import OrderHistoryMypayment from '../pages/OrderHistoryMypayment.vue';
import ManageShop from '../pages/ManageShop.vue';
import ShieldsPlayShields from '../pages/ShieldsPlayShields.vue';
import LibrarySelection from '../components/partials/LibrarySelection.vue';
import ProductOfLingoKids from '../components/partials/ProductOfLingoKids.vue';
// import WorldMap from '../pages/WorldMap.vue';
import countrySelection from '../pages/CountrySelection.vue';
import Blog from '../pages/Blog.vue';
import BlogDetails from '../pages/BlogDetails.vue';
import ShieldsDetails from '../pages/ShieldsDetails.vue';
import LingoKidsBlog from '../pages/LingoKidsBlog.vue';
import SilverHonorBlog from '../pages/SilverHonorBlog.vue';
import MajikKidsBlog from '../pages/MajikKidsBlog.vue';
import DigitalPlaygroundBlog from '../pages/DigitalPlaygroundBlog.vue';

export default [
  // {
  //   path: '/world-map',
  //   component: WorldMap,
  //   // beforeEnter: IsSelected,
  //   name: 'world-map',
  // },
  {
    path: '/',
    component: Home,
    beforeEnter: IsAuthenticated,
    // beforeEnter: IsAuthenticated,
    name: 'home',
  },
  {
    path: '/about',
    component: About,
    beforeEnter: IsAuthenticated,
    name: 'about',
  },
  {
    path: '/product-collections',
    component: ProductCollections,
    beforeEnter: IsAuthenticated,
    name: 'ProductCollections',
  },
  {
    path: '/app',
    component: AppStoryphones,
    beforeEnter: IsAuthenticated,
    name: 'appStoryphones',
  },
  {
    path: '/cart',
    component: Cart,
    beforeEnter: IsAuthenticated,
    name: 'cart',
  },
  {
    path: '/checkout',
    component: Checkout,
    beforeEnter: IsAuthenticated,
    name: 'checkout',
  },
  {
    path: '/coming-soon',
    component: ComingSoon,
    beforeEnter: IsAuthenticated,
    name: 'comingSoon',
  },

  {
    path: '/signup',
    component: Signup,
    beforeEnter: IsAuthenticated,
    name: 'signup',
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: IsLoggedIn,
    name: 'login',
  },
  {
    path: '/forget-password',
    component: ForgetPassword,
    beforeEnter: IsAuthenticated,
    name: 'forget-password',
  },
  {
    path: '/auth/reset-password-confirm/:uid/:token',
    component: ResetPassword,
    beforeEnter: IsAuthenticated,
    name: 'resetPassword',
  },
  {
    path: '/reset-password-email',
    component: ResetPasswordEmail,
    beforeEnter: IsAuthenticated,
    name: 'reset-password-email',
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
    beforeEnter: IsAuthenticated,
    name: 'confirm-email',
  },
  {
    path: '/my-account',
    component: MyAccount,
    beforeEnter: IsAuthenticated,
    name: 'my-account',
  },
  {
    path: '/order-history',
    component: OrderHistory,
    beforeEnter: IsAuthenticated,
    name: 'order-history',
  },
  {
    path: '/order-history-details/:id',
    component: OrderHistoryDetails,
    beforeEnter: IsAuthenticated,
    name: 'order-history-details',
  },

  {
    path: '/wishlist',
    component: Wishlist,
    beforeEnter: IsAuthenticated,
    name: 'Wishlist',
  },
  {
    path: '/playshield-plus',
    component: PlayShieldPlus,
    name: 'playshield-plus',
  },
  {
    path: '/custom-playlist',
    component: CustomPlaylist,
    beforeEnter: IsUser,
    name: 'custom-playlist',
  },
  {
    path: '/add-new-address',
    component: AddNewAddress,
    beforeEnter: IsAuthenticated,
    name: 'add-new-address',
  },
  {
    path: '/edit-address/:id',
    component: EditAddress,
    beforeEnter: IsAuthenticated,
    name: 'edit-address',
  },
  {
    path: '/shields',
    component: Shields,
    beforeEnter: IsAuthenticated,
    name: 'shields',
  },
  {
    path: '/contact-us',
    component: ContactUs,
    beforeEnter: IsAuthenticated,
    name: 'contactUs',
  },
  {
    path: '/affiliate-program',
    component: AffiliateProgram,
    beforeEnter: IsAuthenticated,
    name: 'affiliateProgram',
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    beforeEnter: IsAuthenticated,
    name: 'termsAndConditions',
  },
  {
    path: '/privacy',
    component: Privacy,
    beforeEnter: IsAuthenticated,
    name: 'privacy',
  },
  {
    path: '/warranty-registration',
    component: WarrantyRegistration,
    beforeEnter: IsAuthenticated,
    name: 'warrantyRegistration',
  },
  {
    path: '/operation-guide',
    component: OperationGuide,
    beforeEnter: IsAuthenticated,
    name: 'operationGuide',
  },
  {
    path: '/terms-of-service',
    component: TermsOfService,
    beforeEnter: IsAuthenticated,
    name: 'termsOfService',
  },
  {
    path: '/refund-policy',
    component: RefundPolicy,
    beforeEnter: IsAuthenticated,
    name: 'refundPolicy',
  },
  {
    path: '/faq',
    component: FAQ,
    beforeEnter: IsAuthenticated,
    name: 'faq',
  },
  {
    path: '/setup',
    component: UserManual,
    beforeEnter: IsAuthenticated,
    name: 'user-manual',
  },
  {
    path: '/test',
    component: Test,
    name: 'test',
  },
  {
    path: '/cart-details/:id',
    component: CartDetails,
    beforeEnter: IsAuthenticated,
    name: 'cart-details',
  },
  {
    path: '/paymentsuccess/:id',
    component: PaymentSuccess,
    beforeEnter: IsAuthenticated,
    name: 'paymentSuccess',
  },
  {
    path: '/update-cart',
    component: UpdateCart,
    beforeEnter: IsAuthenticated,
    name: 'updateCart',
  },
  {
    path: '/products/disney',
    component: ProductDisney,
    beforeEnter: IsAuthenticated,
    name: 'productDisney',
  },
  {
    path: '/products/lingoKids',
    component: ProductOfLingoKids,
    beforeEnter: IsAuthenticated,
    name: 'productLingoKids',
  },
  {
    path: '/products/storyphones',
    component: Product,
    beforeEnter: IsAuthenticated,
    name: 'product',
  },
  {
    path: '/reviews-and-ratings',
    component: ReviewsAndRatings,
    beforeEnter: IsAuthenticated,
    name: 'reviews-and-ratings',
  },
  {
    path: '/category/:id',
    component: AllShields,
    beforeEnter: IsAuthenticated,
    name: 'all-shields',
  },
  {
    path: '/auth/activate/:uid/:token',
    component: Verification,
    beforeEnter: IsAuthenticated,
    name: 'activate',
  },

  {
    path: '/search-result',
    component: SearchResult,
    beforeEnter: IsAuthenticated,
    name: 'search-result',
  },
  {
    path: '/licence-agreement',
    component: LicenceAgreement,
    beforeEnter: IsAuthenticated,
    name: 'licence-agreement',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    beforeEnter: IsAuthenticated,
    name: 'privacy-policy',
  },
  {
    path: '/recording',
    component: RecordingPage,
    beforeEnter: IsAuthenticated,
    name: 'recording',
  },
  {
    path: '/library',
    component: Library,
    beforeEnter: IsAuthenticated,
    name: 'library',
  },
  {
    path: '/success-password',
    component: SuccessPassword,
    beforeEnter: IsAuthenticated,
    name: 'success-password',
  },
  {
    path: '/guest-recording/:token',
    component: GuestRecording,
    name: 'guest-recording',
  },
  {
    path: '/marketplace',
    component: Marketplace,
    beforeEnter: IsAuthenticated,
    name: 'marketplace',
  },
  {
    path: '/marketplace-profile',
    component: MarketplaceProfile,
    beforeEnter: IsAuthenticated,
    name: 'marketplace-profile',
  },
  {
    path: '/marketplace-story-details',
    component: MarketplaceStoryDetails,
    beforeEnter: IsAuthenticated,
    name: 'marketplace-story-details',
  },
  {
    path: '/marketplace-login',
    component: MarketplaceLogin,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-login',
  },
  {
    path: '/marketplace-signup',
    component: MarketplaceSignup,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-signup',
  },
  {
    path: '/marketplace-update-profile',
    component: MarketplaceUpdateProfile,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-update-profile',
  },
  {
    path: '/marketplace-my-playlist',
    component: MarketplaceMyPlaylist,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-my-playlist',
  },
  {
    path: '/marketplace-payments',
    component: MarketplacePayments,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-payments',
  },
  {
    path: '/marketplace-creater-profile',
    component: MarketplaceCreaterProfile,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-creater-profile',
  },
  {
    path: '/marketplace-home',
    component: MarketPlaceHome,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-home',
  },
  {
    path: '/marketplace-author',
    component: MarketplaceAuthorDetails,
    beforeEnter: IsLoggedIn,
    name: 'marketplace-author',
  },
  {
    path: '/mystory-content',
    component: MystoryContent,
    beforeEnter: IsAuthenticated,
    name: 'mystory-content',
  },
  {
    path: '/orderhistory-payment',
    component: OrderHistoryMypayment,
    beforeEnter: IsAuthenticated,
    name: 'orderhistory-payment',
  },
  {
    path: '/manageshop',
    component: ManageShop,
    beforeEnter: IsAuthenticated,
    name: 'manageshop',
  },
  {
    path: '/shields-playShields',
    component: ShieldsPlayShields,
    beforeEnter: IsAuthenticated,
    name: 'shields-playShields',
  },
  {
    path: '/library-playshield',
    component: LibrarySelection,
    beforeEnter: IsAuthenticated,
    name: 'library-playshield',
  },
  {
    path: '/country-selection',
    component: countrySelection,
    beforeEnter: IsAuthenticated,
    name: 'country-selection',
  },
  {
    path: '/blog',
    component: Blog,
    // beforeEnter: IsLoggedIn,
    name: 'blog',
  },
  {
    path: '/blog-details',
    component: BlogDetails,
    // beforeEnter: IsLoggedIn,
    name: 'blog-details',
  },
  {
    path: '/shields-details/:id',
    component: ShieldsDetails,
    // beforeEnter: IsAuthenticated,
    name: 'shields-details',
  },
  {
    path: '/lingoKids-blog',
    component: LingoKidsBlog,
    // beforeEnter: IsLoggedIn,
    name: 'lingoKids-blog',
  },
  {
    path: '/silverHonor-blog',
    component: SilverHonorBlog,
    // beforeEnter: IsLoggedIn,
    name: 'silverHonor-blog',
  },
  {
    path: '/digital-playground-blog',
    component: DigitalPlaygroundBlog,
    // beforeEnter: IsLoggedIn,
    name: 'digital-playground-blog',
  },
  {
    path: '/MajikKids-blog',
    component: MajikKidsBlog,
    // beforeEnter: IsLoggedIn,
    name: 'MajikKids-blog',
  },
];
