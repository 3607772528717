<template>
  <PageHeader />
  <section class="blog">
    <div class="container mt-5">
      <div class="row mb-5">
        <div class="col-md-8">
          <div class="blog-content">
            <h3 class="blog-h3 mb-3">
              ONANOFF Partners with Majik Kids to Bring New Stories to StoryShields!
            </h3>
            <p class="blog-p">
              Exciting things are on the horizon for young listeners and their families! ONANOFF and
              StoryPhones are thrilled to announce a wonderful new collaboration with
              <a href="https://majikkids.com/" class="">MajikKids</a>, creators of heartwarming and imaginative
              stories designed to captivate children and inspire families . Together, we’re bringing....
            </p>
            <div class="wave-btn-block mb-4">
              <router-link to="/MajikKids-blog" class="blob-btn fill-bg btn-lg">
                Continue reading...
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="container">
            <img
              src="@/assets/images/MajikKids.jpg"
              alt="MajikKids"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-4">
          <div class="container">
            <img
              src="@/assets/images/blog-4-intro-image.jpg"
              alt="lingokids-StoryPhones"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-md-8">
          <div class="blog-content">
            <h3 class="blog-h3 mb-3">
              ONANOFF Secures Silver Honor at 2023 EU Product Safety Awards for Revolutionary StoryPhones.
            </h3>
            <p class="blog-p">
              In a celebration of ingenuity and unwavering dedication to consumer safety, ONANOFF stands tall as a beacon of excellence at the 2023 EU Product Safety Awards, clinching the esteemed Silver Award in the category dedicated to Protecting the Safety of Vulnerable Consumer Groups. This accolade is a testament to....
            </p>
            <div class="wave-btn-block mb-4">
              <router-link to="/silverHonor-blog" class="blob-btn fill-bg btn-lg">
                Continue reading...
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-8">
          <div class="blog-content">
            <h3 class="blog-h3 mb-3">
              Navigating the Digital Playground: A Comprehensive Guide to Managing Screen Time for
              Kids
            </h3>
            <p class="blog-p">
              In today&#39;s digital age, screens have become an integral part of our lives,
              offering both opportunities and challenges, especially when it comes to parenting. As
              technology continues to evolve, parents are faced with the task of effectively
              managing their children&#39;s screen time. We would like to delve deeper into...
            </p>
            <div class="wave-btn-block mb-4">
              <router-link to="/digital-playground-blog" class="blob-btn fill-bg btn-lg">
                Continue reading...
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="container">
            <img
              src="@/assets/images/Digital-Playground-Blog.jpg"
              alt="Digital-Playground"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="row mb-5"> <div class="col-md-4">
        <div class="container">
          <img
            src="@/assets/images/lingokids-StoryPhones.png"
            alt="lingokids-StoryPhones"
            class="img-fluid"
          />
        </div>
      </div>
        <div class="col-md-8">
          <div class="blog-content">
            <h3 class="blog-h3 mb-3">
              Screen-Free FUN: StoryPhones partners with Lingokids for engaging learning.
            </h3>
            <p class="blog-p">
              In today&#39;s digital age, it&#39;s no secret that screens play a significant role in
              children&#39;s lives. However, striking a balance between screen time and other forms
              of engagement is crucial for their holistic development. That is why StoryPhones have
              teamed up with leading early learning platform Lingokids to offer captivating
              learning....
            </p>
            <div class="wave-btn-block mb-4">
              <router-link to="/lingoKids-blog" class="blob-btn fill-bg btn-lg">
                Continue reading...
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-8">
          <div class="blog-content">
            <h3 class="blog-h3 mb-3">Disney 100 Journeys of Wonder</h3>
            <p class="blog-p">
              Disney 100 Journeys of Wonder is a StoryShield for StoryPhones offering an enchanting
              collection of six relaxing looping sounds compiled from the magical worlds of some of
              the best Disney stories. It&#39;s an immersive audio experience with a subtle
              narrative element offering a relaxing ambient audio journey into Disney and
              Pixar&#39;s character worlds....
            </p>
            <div class="wave-btn-block mb-4">
              <router-link to="/blog-details" class="blob-btn fill-bg btn-lg">
                Continue reading...
                <span class="blob-btn__inner">
                  <span class="blob-btn__blobs">
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                    <span class="blob-btn__blob"></span>
                  </span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="container">
            <img
              src="@/assets/images/Disney100_social_post_1.jpg"
              alt="Disney100social"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script>
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';

export default {
  name: 'Blog',

  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style scoped lang="scss">
// .image-container {
//   width: 100%;
//   margin: 20px;
// }
// .image-container img {
//   max-width: 100%;
//   height: auto;
//   float: left;
//   margin-right: 20px;
// }
// .image-container p {
//   width: 50%;
//   margin: 0;
//   padding: 0;
// }
// .clearfix::after {
//   content: '';
//   display: table;
//   clear: both;
// }
</style>
